<!-- eslint-disable max-len -->
<template>
	<div>
		<template v-if="loading">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<section class="main-grid__row user-profile">
				<div class="user-profile__card">
					<div class="user-profile__section">
						<h5 class="user-profile__heading">Basic Information</h5>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Amount:</p>
							<p class="" id="giftCardAmount">
								{{ itemData.amount | currencyFormatter(itemData.currency) }}
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Currency:</p>
							<p id="giftCardCurrency">{{ itemData.currency }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Recipient Type:</p>
							<template v-if="itemData.recipient == 'third_party'">
								<p id="giftCardRecipient">Third Party</p>
							</template>
							<template v-else-if="itemData.recipient == 'self'">
								<p id="giftCardRecipient">Self</p>
							</template>
							<template v-else>
								<p id="giftCardRecipient">Sale</p>
							</template>
						</div>

						<div class="user-profile__item">
							<label for="is_used">Status</label>
							<div>
								<span :class="isUsed" class="pad" id="status">
									{{ itemData.is_used === 1 ? 'Used' : 'Unused' }}
								</span>
							</div>
						</div>

						<template v-if="itemData.recipient === 'third_party'">
							<div class="user-profile__item">
								<p class="user-profile__section__title">Recipient Name:</p>
								<p id="giftCardRecipientName">{{ itemData.recipient_name }}</p>
							</div>

							<div class="user-profile__item">
								<p class="user-profile__section__title">Recipient Email:</p>
								<p id="giftCardRecipientEmail">{{ itemData.recipient_email }}</p>
							</div>
						</template>
					</div>
				</div>
				<div class="user-profile__tabs" ref="tab">
					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="true"
							>
								Details
							</a>
						</li>
					</ul>
					<div class="tab-content" id="myTabContent">
						<div
							class="tab-pane fade show active user-profile__tabs__section"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<div class="settings">
								<form>
									<div class="settings__field">
										<div class="form-row">
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="amount">Amount</label>
													<input
														:class="[{ 'is-invalid': errors.name }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.amount"
														name="key"
														type="text"
														class="form-control"
														id="name"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="currency">Currency</label>
													<input
														:class="[{ 'is-invalid': errors.currency }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.currency"
														name="currency"
														type="text"
														class="form-control"
														id="currency"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="code">Recipient Type</label>
													<input
														:class="[{ 'is-invalid': errors.recipient }]"
														@keydown="clearErrors($event.target.name)"
														v-model="thirdParty"
														name="recipient"
														type="text"
														class="form-control"
														id="recipient"
														disabled
													/>
												</div>
											</div>
											<template v-if="itemData.recipient === 'third_party'">
												<div class="form-group col-md-6">
													<div class="settings__value">
														<label for="recipient_name">Recipient Name</label>
														<input
															:class="[{ 'is-invalid': errors.recipient }]"
															@keydown="clearErrors($event.target.name)"
															v-model="itemData.recipient_name"
															name="recipient_name"
															type="text"
															class="form-control"
															id="recipient_name"
															disabled
														/>
													</div>
												</div>
												<div class="form-group col-md-12">
													<div class="settings__value">
														<label for="date">Created At:</label>
														<p
															:class="[{ 'is-invalid': errors.created_at }]"
															@keydown="clearErrors($event.target.name)"
															id="usedDate"
														>
															{{ CreatedDate }}
														</p>
													</div>
												</div>
											</template>

											<template v-if="itemData.used_at !== null">
												<div class="form-group col-md-12">
													<div class="settings__value">
														<label for="date">Used At:</label>
														<p
															:class="[{ 'is-invalid': errors.used_at }]"
															@keydown="clearErrors($event.target.name)"
															id="usedDate"
														>
															{{ UsedDate }}
														</p>
													</div>
												</div>
											</template>
											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="date">Message:</label>
													<p
														:class="[{ 'is-invalid': errors.message }]"
														@keydown="clearErrors($event.target.name)"
														id="usedDate"
													>
														{{ itemData.message }}
													</p>
												</div>
											</div>

											<template>
												<div class="pt-5 pb-3 text-center font-weight-bold">
													Buyer's Details
												</div>
												<div class="form-group col-md-12">
													<div class="settings__value">
														<label for="recipient_name">Buyer Name</label>
														<input
															:class="[{ 'is-invalid': errors.buyer_name }]"
															@keydown="clearErrors($event.target.name)"
															v-model="giftcardBuyer"
															name="buyer_name"
															type="text"
															class="form-control"
															id="buyer_name"
															disabled
														/>
													</div>
												</div>
												<div class="form-group col-md-6">
													<div class="settings__value">
														<label for="recipient_name">Buyer Email</label>
														<input
															:class="[{ 'is-invalid': errors.buyer_name }]"
															@keydown="clearErrors($event.target.name)"
															v-model="itemData.buyer.email"
															name="buyer_email"
															type="text"
															class="form-control"
															id="buyer_email"
															disabled
														/>
													</div>
												</div>
												<div class="form-group col-md-6">
													<div class="settings__value">
														<label for="recipient_name">Buyer Phone</label>
														<input
															:class="[{ 'is-invalid': errors.buyer_name }]"
															@keydown="clearErrors($event.target.name)"
															v-model="itemData.buyer.phone"
															name="buyer_phone"
															type="text"
															class="form-control"
															id="buyer_phone"
															disabled
														/>
													</div>
												</div>
											</template>
											<template v-if="itemData.used_by_id !== null">
												<div class="pt-5 pb-3 text-center font-weight-bold">User's Details</div>
												<div class="form-group col-md-12">
													<div class="settings__value">
														<label for="recipient_name">User Name</label>
														<input
															:class="[{ 'is-invalid': errors.user_name }]"
															@keydown="clearErrors($event.target.name)"
															v-model="giftcardUser"
															name="user_name"
															type="text"
															class="form-control"
															id="user_name"
															disabled
														/>
													</div>
												</div>
												<div class="form-group col-md-6">
													<div class="settings__value">
														<label for="recipient_name">User Email</label>
														<input
															:class="[{ 'is-invalid': errors.user_name }]"
															@keydown="clearErrors($event.target.name)"
															v-model="itemData.used_by.email"
															name="user_email"
															type="text"
															class="form-control"
															id="user_email"
															disabled
														/>
													</div>
												</div>
												<div class="form-group col-md-6">
													<div class="settings__value">
														<label for="recipient_name">User Phone</label>
														<input
															:class="[{ 'is-invalid': errors.user_phone }]"
															@keydown="clearErrors($event.target.name)"
															v-model="itemData.used_by.phone"
															name="user_phone"
															type="text"
															class="form-control"
															id="user_phone"
															disabled
														/>
													</div>
												</div>
											</template>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import Loader from '../../components/Loader';

export default {
	name: 'GiftCardDetails',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true
		};
	},
	created() {
		this.fetchGiftCardDetails(this.$route.params.id);
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.itemData = this.getGiftCardDetails;
				this.loading = false;
			}
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getGiftCardDetails']),

		thirdParty() {
			return this.itemData.recipient === 'third_party' ? 'Third Party' : 'Self';
		},
		UsedDate() {
			return moment(this.itemData.used_at).format('LLLL');
		},
		CreatedDate() {
			return moment(this.itemData.created_at).format('LLLL');
		},
		isUsed() {
			return this.itemData.is_used === 0 ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger';
		},
		giftcardUser() {
			if (this.itemData.used_by_id !== null) {
				return `${this.itemData.used_by.firstname} ${this.itemData.used_by.lastname}`;
			}
			return null;
		},
		giftcardBuyer() {
			return `${this.itemData.buyer.firstname} ${this.itemData.buyer.lastname}`;
		}
	},
	methods: {
		...mapActions(['fetchGiftCardDetails'])
	},
	components: {
		Loader
	},
	filters: {
		currencyFormatter(val, currency) {
			const formatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency,
				minimumFractionDigits: 2
			});
			return formatter.format(val);
		},
		date(val) {
			return moment(val).format('LLLL');
		}
	}
};
</script>

<style lang="scss" scoped>
.settings {
	&--wide {
		max-width: 100%;
	}
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
	.row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}
.bold {
	font-weight: bold;
	font-size: 16px;
	margin-right: 5px;
}
.pad {
	padding: 5px 10px;
}
</style>
